<!--
 * @Author: ZHONGHUIDI
 * @Date: 2021-09-30 08:13:45
 * @LastEditTime: 2022-07-18 11:44:08
 * @LastEditors: ZHONGHUIDI
 * @Description: In User Settings Edit
 * @FilePath: \NvcSmartFAQ\QAPreject\src\views\article.vue
-->
<template>
    <div class="z-0 w-full h-full pb-6 bg-white" v-if="artcleDetail" >
        <div class="px-4 pt-4 pb-4 text-xl">{{artcleDetail.theTitle}}</div>
        <div class="flex items-center justify-between px-4 pb-4 text-xs text-gray-400 border-b">
            <div>更新时间: {{artcleDetail.creation}}</div>
        </div>
        <div v-html="artcleDetail.theDisplayValue"   class="w-full px-4 pt-4 pb-6 listItem"  >
        </div> 
        
        <div v-if="artcleDetail.connectDeviceTypes.length!=0 || artcleDetail.attachments.length!=0 || artcleDetail.videos.length!=0" >
            <div class="w-full h-3 bg-gray-100"></div>
            <div class="px-4 pt-4 text-lg text-black rounded-t-xl" v-if='artcleDetail.videos.length!=0'>相关视频</div>
            <div  class="lg:hidden" >
                <div class="flex flex-wrap p-4 " >
                    <div  class="w-full mb-4" v-for="item in artcleDetail.videos" :key="item.id">
                        <video  controls  >
                            <source :src="item.url" type="video/mp4"  > 
                        </video> 
                        <span>{{item.name}}</span>
                    </div>
                </div>
            </div>
            <div class="hidden lg:block">
                <div class="flex flex-wrap pt-4 pl-4 " >
                    <div  class="w-1/3 mb-4" v-for="item in artcleDetail.videos" :key="item.id">
                        <video  controls  style="width:96%; textalign:center" >
                            <source :src="item.url" type="video/mp4"  > 
                        </video> 
                        <span>{{item.name}}</span>
                    </div>
                </div> 
            </div>
           


            <div class="px-4 pt-4 text-lg text-black rounded-t-xl" v-if='artcleDetail.connectDeviceTypes.length!=0'>相关设备</div>
            <div class="p-4 leading-10">
                <span v-for="item in artcleDetail.connectDeviceTypes" :key="item.id" 
                class="px-4 py-2 mb-4 mr-3 text-sm text-gray-600 bg-gray-100 rounded cursor-pointer hover:bg-green-700 hover:text-white" 
                @click="devIdClick(item)">
                    {{item.name}}
                </span>
            </div>            
            <div class="px-4 pt-4 text-lg text-black" v-if='artcleDetail.attachments.length!=0'>附件下载</div>
            <div class="p-4">
                <div   class="w-full p-4 mb-2 text-sm text-green-700 bg-gray-100 rounded-lg hover:bg-green-700 hover:text-white" v-for="item in artcleDetail.attachments" :key="item.id">
                    <a :href="'/api/attach/Download?id='+item.id" :download="item.name" >
                        {{item.name}}
                    </a>
                </div>
            </div>
        </div> 
    
    </div>
</template>

<script>
import { ref, onMounted,computed  } from 'vue'
import { useStore } from 'vuex'
import { useRouter,useRoute } from 'vue-router'
import { searchArticleId, searchArticleList } from "../api/article";

export default {
    name: 'article',
    props: {},
    components: {
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const store = useStore()
        onMounted(() => {
            getArtData();
        });
        const artcleDetail = ref();
        const slideMenu = computed(() => store.state.slideMenu); 
        const getArtData = () => {
            var params = {}
            params.id = route.query.id
            searchArticleId(params).then(res => {
                artcleDetail.value = res.data.data
                store.commit('SET_ROOTID', res.data.data.articleCateId)

                
                let id=res.data.data.articleCateId
                slideMenu.value.forEach((val) => { 
                    val.children.forEach((row) => { 
                        if(row.id==id){
                            if(val.id==row.parentId){
                                val.isShow=true
                                // store.commit('SET_ROOTNAME',  val.name)
                            }
                        }
                    }); 
                });
            })

        }
        
        const devIdClick = (item) => {
            var params = {}
            params.BelongType = ''
            params.CategoryId = ''
            params.SortType = ''
            params.ConnectDeviceType = item.id
            params.PageIndex = ''
            params.PageSize = '15'
            params.SearchKeyWord = ''
            searchArticleList(params).then(res => { 
                store.commit('SET_LIST', res.data.data)
                store.commit('SET_ROOTNAME', item.name)
                const arr={
                    pageIndex:res.data.pageIndex,
                    pageSize:res.data.pageSize,
                    totalCount:res.data.totalCount,
                }
                console.log('art',arr);
                store.commit('SET_PAGE', arr)
                router.push({ path: "/" });
                store.commit('SET_INTRO',false)
            })
        }
        return { getArtData, artcleDetail, devIdClick,slideMenu }
    }
}
</script>
<style scoped >
.listItem  >>> ol { list-style: revert;padding-left: 1rem;}
.listItem  >>> ul { list-style: revert;padding-left: 1rem;} 
</style>